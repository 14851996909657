<script setup lang="ts">
import VModal from '~/components/common/VModal.vue';
import type { Project } from '~/api/types';

type Props = {
  project: Project;
};

const props = defineProps<Props>();

const analyticsStore = useAnalyticsStore();
const jivoStore = useJivoStore();

const isVoted = useCookie<boolean>('isVotedStructure', { maxAge: 604800 });
const isModalOpen = ref(false);

const onOpen = () => {
  analyticsStore.clickChangeStructure(props.project.id);
  isModalOpen.value = true;
  jivoStore.isHidden = true;
};

const onClose = () => {
  isModalOpen.value = false;
  jivoStore.isHidden = false;
};

const voteYes = () => {
  analyticsStore.changeStructureVoteYes(props.project.id);
  isVoted.value = true;
  onClose();
};

const voteNo = () => {
  analyticsStore.changeStructureVoteNo(props.project.id);
  isVoted.value = true;
  onClose();
};
</script>

<template>
  <v-button
    v-if="!isVoted"
    schema="outline"
    @click="onOpen"
  >
    Изменить
  </v-button>
  <v-modal
    :open="isModalOpen"
    @close="onClose"
  >
    <div class="change-structure-modal">
      <h1>Редактирование в разработке</h1>
      <p>Скоро здесь появится возможность редактировать содержание. Вам это было бы полезно?</p>
      <div class="change-structure-modal__body__button-group">
        <v-button
          schema="outline"
          medium
          @click="voteYes"
        >
          Полезно
        </v-button>
        <v-button
          schema="outline"
          medium
          @click="voteNo"
        >
          Бесполезно
        </v-button>
      </div>
    </div>
  </v-modal>
</template>

<style scoped lang="scss">
.change-structure-modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  text-align: center;

  > h1 {
    font-size: 22px;
    margin: 0;
  }

  > p {
    margin-top: -8px;
    font-size: 16px;
  }

  .change-structure-modal__body__button-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;

    > button {
      width: 100%;
      text-wrap: nowrap;
    }
  }
}
</style>
