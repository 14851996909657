<script setup lang="ts">
import { onMounted } from 'vue';
import { useAuthStore } from '~/stores/AuthStore';

const emit = defineEmits(['closeAuth']);
const authStore = useAuthStore();

onMounted(() => {
  if (!window) {
    return;
  }

  window.YaAuthSuggest?.init(
    {
      client_id: '6f2a890c530e41b9a4e459156c472d8e',
      response_type: 'token',
      redirect_uri: `${window.location.origin}/yandex`,
    },
    `${window.location.origin}/yandex`,
    {
      view: 'button',
      parentId: 'yandex_suggest_button_container',
      buttonSize: 'm',
      buttonView: 'main',
      buttonTheme: 'light',
      buttonBorderRadius: '8',
      buttonIcon: 'ya',
    }
  )
    .then(({ handler }) => handler())
    .then(async ({ access_token }) => {
      await authStore.loginByYandex(access_token);
      emit('closeAuth');
    })
    .catch((error) => {
      if (error.code !== 'in_progress') console.log('Yandex Auth Suggest error', error);
    });
});
</script>

<template>
  <div
    id="yandex_suggest_button_container"
    class="mt-2 yandex"
  >
    <div></div>
  </div>
</template>

<style scoped lang="scss">
.yandex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  background-color: black;
  color: white;
  font-weight: 600;
  border-radius: 8px;
}
</style>
