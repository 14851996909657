<script setup lang="ts">
import VModal from '~/components/common/VModal.vue';
import VButton from '~/components/VButton.vue';
import type { Project } from '~/api/types';
import { Check, Copy } from 'lucide-vue-next';

const props = defineProps<{ project: Project; isOpenByClick: boolean }>();
const emit = defineEmits(['close']);

const isOpen = ref(false);
watch(
  () => props.isOpenByClick,
  (val) => {
    if (val) isOpen.value = true;
  }
);

const isCopied = ref(false);

const copyCommentToClipboard = () => {
  const ratingComment = props.project.rating_comment;
  if (ratingComment) navigator.clipboard.writeText(ratingComment);
  isCopied.value = true;
  setTimeout(() => {
    isCopied.value = false;
  }, 5 * 1000);
};

const open = () => {
  isOpen.value = true;
};
const close = () => {
  isOpen.value = false;
  emit('close');
};

watch(isOpen, (val) => {
  if (val) open();
  else close();
});

watch(
  () => props.project.rating,
  (rating) => {
    if (rating && rating >= 4 && props.project.entity != 'final-essay') open();
  }
);
</script>

<template>
  <v-modal
    :open="isOpen"
    :title="`Получите возврат средств`"
    @close="close"
  >
    <div class="otzovik-modal">
      <div class="otzovik-modal__container">
        <p>Помогите другим определиться с выбором: оставьте отзыв на Отзовик.ру, и мы вернем вам полную стоимость проекта.</p>
        <div class="list-block">
          <span>Напишите отзыв, прикрепив к нему:</span>
          <ul>
            <li><span class="list-number">1</span><span>Скриншот вашего проекта</span></li>
            <li><span class="list-number">2</span> <span>Чек об оплате услуги</span></li>
            <li>
              <span class="list-number">3</span> <span>Мы увидим ваш отзыв на сайте и в течение трёх суток вернем средства на карту</span>
            </li>
          </ul>
        </div>
        <div class="otzovik-modal__buttons">
          <v-button
            v-if="props.project.rating_comment?.length"
            medium
            schema="secondary"
            class="w-full"
            @click="copyCommentToClipboard"
          >
            <template v-if="!isCopied">
              <Copy
                width="20"
                height="20"
              />
              <span>Скопировать текст отзыва</span>
            </template>

            <template v-else>
              <Check
                width="20"
                height="20"
              />
              <span>Отзыв скопирован</span>
            </template>
          </v-button>
          <a
            href="https://otzovik.com/postreview.php?pid=2398245"
            target="_blank"
          >
            <v-button
              medium
              class="w-full"
              >Перейти на Отзовик</v-button
            >
          </a>
        </div>
      </div>
    </div>
  </v-modal>
</template>

<style lang="scss">
.otzovik-modal {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: $foreground-contrast;
  text-wrap: pretty;

  .modal__container {
    padding: 24px;
  }

  .otzovik-modal__container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .list-block {
      display: flex;
      flex-direction: column;
      gap: 12px;

      li {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: start;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        .list-number {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          background: $background-button-ghost-hover;
          min-height: 24px;
          min-width: 24px;
        }
      }
    }

    .otzovik-modal__buttons {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
</style>
