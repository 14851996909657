<script setup lang="ts">
import { iconColors } from 'assets/js/utils.js';
import { X } from 'lucide-vue-next';
import { computed } from 'vue';

type Props = {
  title?: string;
  width?: string;
  height?: string;
  closable?: boolean;
  overflow?: string;
};
const props = defineProps<Props>();

const open = ref(false);
const emit = defineEmits(['open', 'close']);
const root = ref<HTMLElement | null>(null);
const sizeStyle = computed(() => {
  return {
    width: props.width,
    height: props.height,
    overflow: props.overflow,
  };
});

onMounted(() => {
  if (root.value) {
    document.body.appendChild(root.value);
  }
  document.documentElement.classList.toggle('modal-opened', true);
  open.value = true;
  emit('open');
});

onUnmounted(() => {
  if (root.value?.parentElement) root.value?.parentElement.removeChild(root.value);
  if (document.getElementsByClassName('modal').length === 0) document.documentElement.classList.toggle('modal-opened', false);
});

function handleAfterLeave() {
  if (document.getElementsByClassName('modal').length === 0) document.documentElement.classList.toggle('modal-opened', false);
  emit('close');
}

function onClose() {
  if (props.closable) open.value = false;
  emit('close');
}
</script>

<template>
  <transition
    name="modal"
    @after-leave="handleAfterLeave"
  >
    <div
      v-if="open"
      ref="root"
      class="modal"
      @mousedown.self="onClose"
    >
      <div
        class="modal__container"
        :style="[sizeStyle]"
        @pointerdown.stop
      >
        <button
          v-if="closable"
          class="modal__close-button"
          type="button"
          @click="onClose"
        >
          <X
            :size="24"
            :color="iconColors.primary"
          />
        </button>
        <div class="modal__body">
          <h3
            v-if="title"
            class="modal__title"
          >
            {{ title }}
          </h3>
          <slot name="default" />
          <div
            v-if="$slots.footer"
            class="modal__footer"
          >
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.modal-opened {
  @include media-breakpoint-up(md) {
    .sticky-menu__content {
      padding-right: 14px;
    }
  }
}

.modal {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(black, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__container {
  top: 50%;
  transform: translateY(-50%);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 90%;
  min-width: 300px;
  position: relative;
  margin: 0 auto;
  background: #fff;
  width: 406px;
  max-width: 95%;
  color: $foreground-contrast;
  padding: 24px;
  pointer-events: auto !important;

  @include media-breakpoint-down(md) {
    width: 100% !important;
    height: 100% !important;
    max-height: 100%;
    max-width: 100%;
    border-radius: 0;
  }
}

.modal__close-button {
  position: absolute;
  z-index: 1;
  outline: none;
  font-weight: 100;
  cursor: pointer;
  line-height: 18px;
  padding: 0;
  margin: 5px;
  width: 46px;
  height: 46px;
  right: 0;
  top: 0;
  opacity: 1;
  transition: all 0.1s;
  flex-shrink: 0;
  background: transparent;
  border: none;
  font-size: 30px;
  color: $foreground-theme;

  &:hover {
    color: rgba($foreground-theme, 0.8);
  }

  &:focus {
    outline: none;
    transform: scale(0.8);
  }

  @include media-breakpoint-down(md) {
    transform: none;
    z-index: 1;
  }

  &.dark {
    color: $foreground-contrast;

    &:hover {
      color: rgba($foreground-contrast, 0.8);
    }
  }
}

.modal__title {
  max-width: 70%;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 16px;
  text-align: start;
}

.modal__footer {
  min-height: 60px;
  background: $foreground-theme;
  color: white;
  margin: 0 -80px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-top: 50px;
  margin-bottom: -25px;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>
