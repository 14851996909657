<script setup lang="ts">
import { getSimilarProject } from '~/api/projects';
import type { Project } from '~/api/types';
import ProjectMainAdv from '~/components/deprecated/ProjectMainAdv.vue';
import NewProjectMainBottom from '~/components/deprecated/NewProjectMainBottom.vue';
import ProjectMainDownload from '~/components/pages/projects/ProjectMainDownload.vue';
import ProjectMainTop from '~/components/deprecated/ProjectMainTop.vue';
import ProjectPageSide from '~/components/pages/projects/ProjectPageSide.vue';
import SimilarProjects from '~/components/pages/projects/SimilarProjects.vue';
import VDivider from '~/components/VDivider.vue';
import VLoader from '~/components/VLoader.vue';
import ProjectMainBottom from '~/components/deprecated/ProjectMainBottom.vue';

type Props = {
  project: Project;
};

const props = defineProps<Props>();

const analyticsStore = useAnalyticsStore();

const { data: similarProjectsData } = await useAsyncData<Project[]>(
  'similar_project',
  () => getSimilarProject(props.project.id.toString()),
  {
    server: false,
    lazy: true,
    default: () => [],
  }
);

watch(
  () => props.project.status,
  (newStatus, oldStatus) => {
    if (newStatus === 0 && oldStatus === undefined) analyticsStore.generationStart(props.project.id);
    if (oldStatus === 0 && newStatus === 1) analyticsStore.generationEnd(props.project.id);
  },
  { immediate: true }
);
</script>

<template>
  <div
    v-if="props.project && props.project.status == 1"
    class="project-page"
  >
    <div class="container">
      <div class="project-page__body">
        <div
          class="project-page__main"
          :class="{ essay: props.project.entity === 'final-essay' }"
        >
          <project-main-top :project="props.project" />
          <v-divider
            v-if="props.project.entity != 'final-essay'"
            is-gray
          />
          <project-main-download
            v-if="props.project?.is_owner && !props.project?.long_poll && props.project?.download_available"
            :project="props.project"
          />
          <template v-if="props.project?.paragraphs?.length">
            <new-project-main-bottom
              v-if="props.project?.is_chapters_displayed"
              :project="props.project"
            />
            <project-main-bottom
              v-else
              :chapters="props.project?.paragraphs"
            />
          </template>
          <project-main-adv
            v-if="!props.project?.payed_status"
            :project="props.project"
          />
        </div>
        <project-page-side :project="props.project" />
      </div>
      <v-divider
        v-if="similarProjectsData.length"
        is-gray
      />
      <similar-projects
        v-if="similarProjectsData.length"
        :similar-projects="similarProjectsData"
      />
    </div>
  </div>
  <v-loader
    v-else
    :data="props.project?.display_status"
  />
</template>

<style lang="scss">
.project-page {
  margin-top: 60px;
  padding-top: 39px;
  @include media-breakpoint-down(md) {
    margin-top: 55px;
    padding-top: 24px;
    overflow: hidden;
  }
}

.project-page__body {
  margin-bottom: 128px;
  display: grid;
  grid-template-columns: 65% 1fr;
  gap: 16px;

  @include media-breakpoint-down(xl) {
    grid-template-columns: 100%;
    gap: 32px;
    margin-bottom: 32px;
  }

  .project-page__main {
    display: flex;
    padding: 32px;
    background: white;
    border-radius: 32px;
    flex-direction: column;
    gap: 32px;
    @include media-breakpoint-down(md) {
      gap: 24px;
      padding: 24px 16px;
    }
  }

  .essay {
    gap: 16px;
    @include media-breakpoint-down(md) {
      gap: 8px;
    }
  }
}
</style>
