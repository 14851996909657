<script setup lang="ts">
import VButton from '~/components/VButton.vue';
import VModal from '~/components/common/VModal.vue';

type Props = {
  isOpen: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits(['close']);

const jivoStore = useJivoStore();

const onErrorClose = () => {
  if (props.isOpen) {
    emit('close');
  }
};

const onSupportClick = () => {
  onErrorClose();
  jivoStore.openChat();
};

const texts = {
  title: 'Возникла ошибка с оплатой',
  description: 'Пожалуйста, попробуйте оплатить проект снова через несколько минут.',
  supportAction: 'Написать в поддержку',
};
</script>

<template>
  <v-modal
    :open="props.isOpen"
    :title="texts.title"
    @close="onErrorClose"
  >
    <div class="action-block__error">
      <span class="action-block__error-text">{{ texts.description }}</span>
      <v-button
        schema="secondary"
        @click="onSupportClick"
      >
        {{ texts.supportAction }}
      </v-button>
    </div>
  </v-modal>
</template>

<style scoped lang="scss">
.action-block__error {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: -8px;

  .action-block__error-text {
    color: $foreground-gray;
    font-size: 16px;
    text-wrap: pretty;
  }
}
</style>
