<script setup lang="ts">
import type { Project } from '~/api/types';
import { entities } from '../../../enitityHelper';

type Props = {
  project: Project;
};

const props = defineProps<Props>();

const userStore = useUserStore();
</script>

<template>
  <div class="title-page">
    <span>Наименование образовательного учреждения</span>
    <div class="title-page__header">
      <span>{{ entities[props.project.entity].name }}</span>
      <span>на тему</span>
      <span>{{ props.project.title }}</span>
    </div>
    <div class="title-page__credentials">
      <div class="title-page__credentials__group">
        <span>Выполнил:</span>
        <span>ФИО</span>
      </div>
      <div class="title-page__credentials__group">
        <span>Руководитель:</span>
        <span>ФИО</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.title-page {
  min-height: 1100px;
  padding: 64px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
  justify-content: start;
  text-align: center;

  @include media-breakpoint-down(lg) {
    min-height: 800px;
    padding: 48px;
    gap: 36px;
  }

  > span:nth-child(1) {
    font-size: 16px;
    font-weight: 700;
  }

  .title-page__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    text-align: center;
    gap: 12px;

    > span:nth-child(1) {
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;

      @include media-breakpoint-down(lg) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    > span:nth-child(2) {
      font-weight: 700;
    }

    > span:nth-child(3) {
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;

      @include media-breakpoint-down(lg) {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}

.title-page__credentials {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-left: auto;
  text-align: start;

  .title-page__credentials__group {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
</style>
