<script setup lang="ts">
import * as VKID from '@vkid/sdk';
import { onMounted } from 'vue';

VKID.Config.set({
  app: 51847309,
  redirectUrl: `${window?.location.origin ?? 'https://begemot.ai'}/vk`,
});

const redirectAuth = useCookie('redirect_auth');
const container = ref<HTMLElement>();
const route = useRoute();

onMounted(() => {
  const oneTap = new VKID.OneTap();
  if (route.name != 'vk') {
    redirectAuth.value = route.fullPath;
  }
  if (container.value) {
    oneTap.render({
      container: container.value,
    });
  }
});
</script>

<template>
  <div
    ref="container"
    class="mt-2"
  />
</template>

<style scoped lang="scss"></style>
