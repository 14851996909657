<script setup lang="ts">
import CopyButton from '~/components/pages/projects/payment/CopyButton.vue';
import PageSideCard from '~/components/cards/PageSideCard.vue';
import type { Project } from '~/api/types';

type Props = {
  project: Project;
};

const props = defineProps<Props>();
</script>

<template>
  <page-side-card
    class="action-block"
    :title="props.project.action_block.title"
  >
    <ul class="action-block__list">
      <li
        v-for="(feature, i) in props.project.action_block.features"
        :key="i"
      >
        <NuxtImg
          src="/images/new/project-advantages/unique.svg"
          :alt="feature"
          width="24"
          height="24"
        />
        <span class="action-block__list__feature">
          {{ feature }}
        </span>
      </li>
    </ul>
    <div ref="actionBlockRef">
      <copy-button :project="project" />
    </div>
  </page-side-card>
</template>

<style scoped lang="scss">
.action-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 32px;
  background: white;

  .foreign-button {
    &:not(:hover) {
      background: white !important;
    }
  }

  .new-button {
    font-weight: 500;
  }

  &__header {
    h5 {
      margin-bottom: 4px;
    }

    p {
      margin-bottom: 0;
      color: #5047e6a3;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      color: $foreground-contrast;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      display: flex;
      gap: 8px;
      margin: 0;
      padding: 0;

      svg {
        opacity: 0.7;
      }
    }
  }

  &_fixed {
    position: fixed;
    z-index: 5;
    bottom: 0;
    left: 50%;
    padding: 16px 20px;
    transform: translateX(-50%);
    width: 100%;
    display: none;
    font-size: 14px;
    font-weight: 500;

    background: white;
    box-shadow: $new-shadow;
    @include media-breakpoint-down(lg) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      box-shadow: 0 -4px 24px rgba(#00000017, 0.08);
    }

    border-radius: 32px 32px 0 0;
  }
}
</style>
