<script setup lang="ts">
import type { Project, StructuredChapter } from '~/api/types.ts';
import ContentPage from '~/components/pages/projects/ContentPage.vue';
import VDivider from '~/components/VDivider.vue';
import ContentListPage from '~/components/pages/projects/ContentListPage.vue';
import TitlePage from '~/components/pages/projects/TItlePage.vue';

interface ProjectMainBottomProps {
  project: Project;
}

const props = defineProps<ProjectMainBottomProps>();

const structuredChapters = computed(() => {
  let chapters: StructuredChapter[] = [];

  props.project.paragraphs.forEach((paragraph) => {
    if (paragraph.chapter_id) {
      if (chapters.find((ch) => ch.id === paragraph.chapter_id)) {
        return chapters[chapters.findIndex((ch) => ch.id === paragraph.chapter_id)]?.paragraphs.push(paragraph);
      }
      return chapters.push({ ...paragraph.chapter!, paragraphs: [paragraph], is_paragraph: false });
    } else {
      return chapters.push({ ...paragraph, paragraphs: [paragraph], is_paragraph: true });
    }
  });

  // добавить нумерацию глав и пунктов через мутацию
  chapters = chapters.map((chapter, chapterIndex) => {
    if (!chapter.is_paragraph) {
      const isAlreadyFormatted = chapter.title.toLowerCase().includes('глава');
      if (!isAlreadyFormatted) chapter.title = `Глава ${chapterIndex}. ${chapter.title}`;

      return {
        ...chapter,
        paragraphs: chapter.paragraphs.map((paragraph, paragraphIndex) => {
          return {
            ...paragraph,
            title: `${chapterIndex}.${paragraphIndex + 1}. ${paragraph.title}`,
          };
        }),
      };
    } else return chapter;
  });

  return chapters;
});
</script>

<template>
  <h1 class="project-page__content-heading">Предпросмотр документа</h1>
  <div
    class="project-page__content"
    :class="{ essay: props.project.entity === 'final-essay' }"
  >
    <div class="project-page__main-bottom">
      <template v-if="props.project.entity != 'final-essay'">
        <TitlePage :project="props.project" />
        <v-divider is-gray />
      </template>
      <ContentListPage
        :project="props.project"
        :chapters="structuredChapters"
      />
      <v-divider is-gray />
      <div>
        <div
          v-for="(chapter, idx) in structuredChapters"
          :key="idx"
          class="project-page__chapter"
        >
          <div
            v-for="paragraph in chapter.paragraphs"
            :key="paragraph.id"
          >
            <content-page
              :paragraph="paragraph"
              :project="props.project"
              :chapter="chapter"
            />
            <v-divider is-gray />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.project-page__content-heading {
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  padding: 0 20px;
  margin: 0 0 -20px 0;
}
.project-page__content {
  background: white;
  border-radius: 32px;
  font-family: 'Times New Roman', serif;

  .project-page__main-bottom {
    display: flex;
    flex-direction: column;

    .project-page__chapter-header {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
    }
  }
}
</style>
