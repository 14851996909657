<script setup lang="ts">
import VDialog from '~/components/common/VDialog.vue';
import VDrawer from '~/components/common/VDrawer.vue';

type Props = {
  title?: string;
  open: boolean;
  closable?: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits(['open', 'close']);

const onClose = () => emit('close');

watch(
  () => props.open,
  (isOpen) => {
    if (isOpen) useJivoStore().isHidden = true;
    else useJivoStore().isHidden = false;
  }
);
</script>

<template>
  <v-drawer
    v-if="!$device.isDesktop"
    :open="props.open"
    class="modal-drawer"
    @close="onClose"
  >
    <slot name="default" />
  </v-drawer>
  <v-dialog
    v-if="$device.isDesktop && props.open"
    :closable="closable"
    :title="title"
    @close="onClose"
  >
    <slot name="default" />
  </v-dialog>
</template>

<style lang="scss">
.modal-drawer {
}
</style>
