<script setup lang="ts">
import ActionBlockTimer from '~/components/pages/projects/actionBlock/ActionBlockTimer.vue';
import { blackFridayEndDate, isBlackFridayNow } from '~/constants/black-friday';
import type { PayItem, Project } from '~/api/types';

type Props = {
  project: Project;
  cart: Array<PayItem>;
};
const props = defineProps<Props>();

const startDate = '2024-11-11T00:00:00';
const targetDate = blackFridayEndDate;
</script>

<template>
  <action-block-timer
    v-if="isBlackFridayNow"
    :start-date="startDate"
    :target-date="targetDate"
    :days="true"
    :hours="true"
    :minutes="true"
    :seconds="true"
    label="-11% на всё с 11.11"
    :project="props.project"
    :cart="cart"
  />
</template>
