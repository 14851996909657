<script setup lang="ts">
import VButton from '~/components/VButton.vue';
import { useElementVisibility } from '@vueuse/core';
import type { Project } from '~/api/types';
import { useJivoStore } from '~/stores/JivoStore';
import { useProjectStore } from '~/stores/ProjectStore';

type Props = {
  project: Project;
  actionBlockRef: HTMLDivElement | null;
};

const props = defineProps<Props>();

const analyticsStore = useAnalyticsStore();
const jivoStore = useJivoStore();
const projectsStore = useProjectStore();

const actionBlockRefIsVisible = useElementVisibility(() => props.actionBlockRef);

watch(actionBlockRefIsVisible, (value) => {
  if (value) jivoStore.resetLayout();
  else jivoStore.bottomOffset = 80;
});

onMounted(() => {
  setTimeout(() => {
    if (projectsStore.isCopied && props.actionBlockRef) {
      props.actionBlockRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
      projectsStore.isCopied = false;
    }
  }, 1000);

  jivoStore.bottomOffset = 80;
});
</script>

<template>
  <div
    v-if="props.project.action_block && !actionBlockRefIsVisible"
    class="action-block_fixed"
    @click="
      () => {
        props.actionBlockRef?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        analyticsStore.clickActionFloatBlock(props.project.id);
      }
    "
  >
    <div class="action-block_fixed__text">
      <span class="action-block_fixed__text__heading">{{ props.project.action_block.title }}</span>
      <span class="action-block_fixed__text__description">{{ props.project.action_block.short_description }}</span>
    </div>
    <v-button
      schema="primary"
      small
      style="white-space: nowrap"
    >
      {{ props.project.action_block.buttonText || 'Купить' }}
    </v-button>
  </div>
</template>

<style scoped lang="scss">
.action-block_fixed {
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 50%;
  padding: 16px 20px;
  transform: translateX(-50%);
  width: 100%;
  display: none;
  font-size: 14px;
  font-weight: 500;

  background: white;
  box-shadow: $new-shadow;
  @include media-breakpoint-down(lg) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    box-shadow: 0 -4px 24px rgba(#00000017, 0.08);
  }

  border-radius: 32px 32px 0 0;

  .action-block_fixed__text {
    display: flex;

    flex-direction: column;
    gap: 4px;

    &__heading {
      color: $foreground-contrast;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
    }

    &__description {
      color: $foreground-theme;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;

      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
</style>
