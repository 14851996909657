<script setup lang="ts">
import { fromNow } from 'assets/js/filters/dayjs';
import type { Project, Service } from '~/api/types';
import getPlural from '../assets/js/filters/plural';
import { entities } from '~/enitityHelper';

interface TitleLayoutProps {
  project: Project;
}

const props = defineProps<TitleLayoutProps>();
const time = ref(fromNow(props.project.created_at));

const translateService = (s: Service) => {
  switch (s) {
    case 'hidden':
      return 'Скрытый';
    case 'images':
      return 'Изображения в тексте';
    case 'text':
      return 'Оплачен';
    case 'use_google':
      return 'Список литературы';
    case 'slides':
      return 'Презентация';
    default:
      return s;
  }
};
</script>

<template>
  <div class="title-layout">
    <div class="title-layout__header">
      <div
        class="title-layout__type"
        itemprop="about"
      >
        {{ entities[props.project.entity].name }}
      </div>
      <div class="title-layout__footer">
        <time
          class="title-layout__time"
          itemprop="dateCreated"
        >
          {{ time.replace(/ /g, '&nbsp;') }}&nbsp;·&nbsp;{{ props.project.views_count }}&nbsp;{{
            getPlural(Number(props.project.views_count), 'просмотры')
          }}
        </time>
      </div>
    </div>
    <h1 itemprop="name">
      {{ props.project.title }}
    </h1>

    <div
      v-if="props.project.is_owner && props.project.services && props.project.services.length"
      class="title-layout__services"
    >
      <span
        v-for="service of props.project.services.filter((s) => s != 'text')"
        :key="service"
        class="title-layout__service"
      >
        {{ translateService(service) }}
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.title-layout {
  display: grid;
  gap: 16px;
  grid-template-columns: 100%;

  .title-layout__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  .title-layout__type {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-edge: cap;
    height: 34px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.15px;
    background: $additional-light-violet;
    color: $additional-dark-violet;
    border-radius: 100px;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  .title-layout__time {
    color: $foreground-gray;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
  }

  h1 {
    color: $foreground-contrast;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    margin: 0;
  }

  .title-layout__services {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;

    // @include media-breakpoint-down(md) {
    //   display: grid;
    //   grid-template-columns: auto auto;
    // }

    .title-layout__service {
      // border: 1px solid rgba(38, 189, 108, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      color: $text-status-success;
      border: 1px solid $text-status-success;
      padding: 12px;
      height: 34px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 100px;

      @include media-breakpoint-down(md) {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
