<script setup lang="ts">
import { getSimilarProject } from '~/api/projects';
import type { Project } from '~/api/types';
import ProjectMainTop from '~/components/pages/projects/ProjectMainTop.vue';
import ProjectPageSide from '~/components/pages/projects/ProjectPageSide.vue';
import SimilarProjects from '~/components/pages/projects/SimilarProjects.vue';
import VDivider from '~/components/VDivider.vue';
import VLoader from '~/components/VLoader.vue';
import NewProjectContent from '~/components/pages/projects/NewProjectContent.vue';

type Props = {
  project: Project;
};

const props = defineProps<Props>();
const analyticsStore = useAnalyticsStore();

const { data: similarProjectsData } = await useAsyncData<Project[]>(
  'similar_project',
  () => getSimilarProject(props.project.id.toString()),
  {
    server: false,
    lazy: true,
    default: () => [],
  }
);

watch(
  () => props.project.status,
  (newStatus, oldStatus) => {
    if (newStatus === 0 && oldStatus === undefined) analyticsStore.generationStart(props.project.id);
    if (oldStatus === 0 && newStatus === 1) analyticsStore.generationEnd(props.project.id);
  },
  { immediate: true }
);
</script>

<template>
  <div
    v-if="props.project && props.project.status == 1"
    class="project-page"
  >
    <div class="container">
      <div class="project-page__body">
        <div style="display: flex; flex-direction: column; gap: 40px">
          <div>
            <project-main-top :project="props.project" />
          </div>
          <template v-if="props.project?.paragraphs?.length">
            <new-project-content :project="props.project" />
          </template>
        </div>
        <project-page-side :project="props.project" />
      </div>
      <v-divider
        v-if="similarProjectsData.length"
        is-gray
      />
      <similar-projects
        v-if="similarProjectsData.length"
        :similar-projects="similarProjectsData"
      />
    </div>
  </div>
  <v-loader
    v-else
    :data="props.project?.display_status"
  />
</template>

<style lang="scss">
.project-page {
  margin-top: 60px;
  padding-top: 39px;
  @include media-breakpoint-down(md) {
    margin-top: 55px;
    padding-top: 24px;
    overflow: hidden;
  }
}

.project-page__body {
  margin-bottom: 128px;
  display: grid;
  grid-template-columns: 65% 1fr;
  gap: 16px;

  @include media-breakpoint-down(xl) {
    grid-template-columns: 100%;
    gap: 32px;
    margin-bottom: 32px;
  }
}
</style>
