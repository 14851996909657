<script setup lang="ts">
import VCollapse from '~/components/common/VCollapse.vue';
import VDivider from '~/components/VDivider.vue';
import VSwitch from '~/components/common/VSwitch.vue';
import PaymentButton from '~/components/pages/projects/payment/PaymentButton.vue';
import { useAnalyticsStore } from '~/stores/AnalyticsStore';
import type { PayItem, Project, Service } from '~/api/types';
import { getActionBlock } from '~/api/projects';

type Props = {
  project: Project;
  cart: Array<PayItem>;
};

const props = defineProps<Props>();
const emit = defineEmits(['update-cart', 'update-action-block']);

const analyticsStore = useAnalyticsStore();
const switchValue = ref<Service[]>(
  props.project.action_block.items.filter((item) => item.optional && item.selected).map((item) => item.service)
);
const loading = ref(false);

const isCollapseOpen = ref(props.project.action_block.items.filter((v) => v.optional).map(() => false));

const basePrice = computed(() => props.project.action_block.items.filter((v) => !v.optional).reduce((a, b) => a + b.price, 0));
const discountBasePrice = computed(() =>
  props.project.action_block.items.filter((v) => !v.optional).reduce((a, b) => a + (b.discount_price ?? b.price), 0)
);
const defaultPrice = computed(() => props.cart.reduce((sum: number, item: PayItem) => sum + item.price, 0));
const discountPrice = computed(() => props.cart.reduce((sum: number, item: PayItem) => sum + (item.discount_price ?? item.price), 0));

const changeCart = async (item: PayItem) => {
  if (!item.optional) return;

  const isInCart = props.cart.map((i: PayItem) => i.service).includes(item.service);

  analyticsStore.toggleServices(
    props.project.id,
    props.cart.map((i: PayItem) => i.service)
  );
  if (isInCart) analyticsStore.disableService(props.project.id, item.service);
  else analyticsStore.enableService(props.project.id, item.service);

  const newCart = isInCart ? props.cart.filter((i: PayItem) => i.service !== item.service) : [...props.cart, item];

  if (props.project.action_block.promocode && props.project.action_block.promocode.unit != 'percent') {
    emit(
      'update-action-block',
      await getActionBlock({
        promocode: props.project.action_block.promocode.code,
        projectId: props.project.id,
        services: newCart.map((item) => item.service),
      })
    );
  } else {
    emit('update-cart', newCart);
  }
};

const promocodeUnit = computed(() => props.project.action_block.promocode?.unit);

watchSyncEffect(() => {
  emit('update-cart', [...props.project.action_block.items.filter((item: PayItem) => !item.optional || item.selected)]);
  switchValue.value = props.project.action_block.items.filter((item) => item.optional && item.selected).map((item) => item.service);
});
</script>

<template>
  <div
    :key="props.project.action_block.buttonText"
    class="action-block"
  >
    <div class="action-block__heading">
      <div
        v-if="props.project.action_block.items.find((v) => !v.optional)"
        class="action-block__heading__price-and-icon"
      >
        <NuxtImg
          src="/images/icons/zap.svg"
          alt="Zap"
          width="32"
          height="32"
        />
        <div
          v-if="props.project.action_block.items.find((v) => !v.optional)"
          style="display: flex; flex-direction: row; gap: 8px; align-items: end"
        >
          <span
            v-if="discountBasePrice < basePrice && promocodeUnit != 'money'"
            class="action-block__heading__price-and-icon__base-price"
            style="text-decoration: line-through; font-size: 24px; color: #b2b2b2"
            >{{ basePrice }} ₽
          </span>
          <span
            class="action-block__heading__price-and-icon__base-price"
            style="line-height: 110%"
            >{{ promocodeUnit === 'money' ? basePrice : discountBasePrice ?? basePrice }} ₽</span
          >
        </div>
      </div>
      <div class="action-block__heading__type">
        {{ props.project.action_block.title }}
      </div>
    </div>
    <template v-if="props.project.action_block.features.length">
      <ul class="action-block__list">
        <li
          v-for="(feature, idx) in props.project.action_block.features"
          :key="idx"
        >
          <NuxtImg
            src="/images/new/project-advantages/unique.svg"
            :alt="feature"
            width="24"
            height="24"
          />
          <span>
            {{ feature }}
          </span>
        </li>
      </ul>
    </template>
    <v-divider is-gray />
    <div class="action-block__body">
      <div class="action-block__select-group">
        <template
          v-for="(item, i) in props.project.action_block.items.filter((v) => v.optional)"
          :key="item.service"
        >
          <v-collapse
            class="action-block__select-group__collapse"
            caret-position="left"
            caret-type="chevron"
            :is-open="isCollapseOpen[i]"
            @click="
              () => {
                if (!isCollapseOpen[i]) analyticsStore.openServiceDescription(item.service, props.project.id);
                isCollapseOpen = isCollapseOpen.map((v, idx) => (i === idx ? !v : false));
              }
            "
          >
            <template #title>
              <div class="action-block__select-group__collapse__title">
                <p style="text-wrap: nowrap; letter-spacing: -0.01rem; font-weight: 600">
                  {{ item.title }}
                </p>
                <div style="display: flex; gap: 16px; text-wrap: nowrap">
                  <span style="display: flex; gap: 6px">
                    <span
                      v-if="item.discount_price < item.price && promocodeUnit != 'money'"
                      class="action-block__select-item-price"
                      style="color: #8c8c8c; text-decoration: line-through"
                      >{{ item.price }}&nbsp;₽</span
                    >
                    <span class="action-block__select-item-price"
                      >{{ promocodeUnit === 'money' ? item.price : item.discount_price ?? item.price }}&nbsp;₽</span
                    >
                  </span>
                  <v-switch
                    v-model="switchValue"
                    :value="item.service"
                    :disabled="loading"
                    @click.stop
                    @update:model-value="changeCart(item)"
                  />
                </div>
              </div>
            </template>
            <template
              v-if="item.subtitle"
              #content
            >
              <div class="action-block__select-group__collapse__content">{{ item.subtitle }}</div>
            </template>
          </v-collapse>
        </template>
      </div>
    </div>
    <div v-if="defaultPrice">
      <PaymentButton
        :cart="props.cart"
        :project="props.project"
        type="ru"
      />
      <PaymentButton
        :cart="props.cart"
        :project="props.project"
        type="sbp"
      />
      <PaymentButton
        :cart="props.cart"
        :project="props.project"
        type="en"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.action-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 32px;
  background: white;

  .foreign-button {
    &:not(:hover) {
      background: white !important;
    }
  }

  .new-button {
    font-weight: 500;
  }

  &__heading {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__type {
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
      width: 70%;
    }

    &__price-and-icon {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &__base-price {
        font-size: 36px;
        font-weight: 500;
        line-height: 130%;
        color: $foreground-theme;
      }
    }
  }

  &__header {
    h5 {
      margin-bottom: 4px;
    }

    p {
      margin-bottom: 0;
      color: #5047e6a3;
    }
  }

  &__select-group {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__collapse {
      background: $background-theme-fade;
      border-radius: 12px;
      padding: 12px;

      &__title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        font-size: 14px;
      }

      &__content {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $foreground-gray;

        &:first-letter {
          text-transform: capitalize;
        }
      }
    }
  }

  &__select-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 10px 12px;
    border-radius: 12px;
    background: white;
  }

  &__select-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    gap: 8px;

    p {
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      align-items: center;
    }
  }

  &__select-item-price {
    color: $foreground-theme;
    font-weight: 600;
  }

  &__select-item-body {
    color: $foreground-gray;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      color: $foreground-contrast;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      display: flex;
      gap: 8px;
      margin: 0;
      padding: 0;

      svg {
        opacity: 0.7;
      }
    }
  }

  &_fixed {
    position: fixed;
    z-index: 5;
    bottom: 0;
    left: 50%;
    padding: 16px 20px;
    transform: translateX(-50%);
    width: 100%;
    display: none;
    font-size: 14px;
    font-weight: 500;

    background: white;
    box-shadow: $new-shadow;
    @include media-breakpoint-down(lg) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      box-shadow: 0 -4px 24px rgba(#00000017, 0.08);
    }

    border-radius: 32px 32px 0 0;

    .action-block_fixed__text {
      display: flex;

      flex-direction: column;
      gap: 4px;

      &__heading {
        color: $foreground-contrast;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
      }

      &__description {
        color: $foreground-theme;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;

        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
</style>
