<script setup lang="ts">
import dayjs from 'dayjs';
import type { PayItem, Project } from '~/api/types';
import { getActionBlock } from '~/api/projects';

type Props = {
  project: Project;
  cart: Array<PayItem>;
  label: string | null;
  now?: string;
  startDate: string;
  targetDate: string;
  days?: boolean;
  hours?: boolean;
  minutes?: boolean;
  seconds?: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits(['update-action-block']);
const now = ref(dayjs(props.now));
const startDate = dayjs(props.startDate);
const targetDate = dayjs(props.targetDate);
const totalDuration = targetDate.diff(startDate);
const difference = ref<number>(0);

// Reactive progress value, updated every second
const progress = ref(0);
const isExpired = computed(() => progress.value === 100);

// Compute the time difference string
const stringDifference = computed(() => {
  const parts: string[] = [];

  if (props.days) {
    const days = Math.floor(difference.value / (1000 * 60 * 60 * 24));
    const formattedDays = String(Math.max(0, days)).padStart(2, '0');
    parts.push(formattedDays);
  }
  if (props.hours) {
    const hours = Math.floor((difference.value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const formattedHours = String(Math.max(0, hours)).padStart(2, '0');
    parts.push(formattedHours);
  }
  if (props.minutes) {
    const minutes = Math.floor((difference.value % (1000 * 60 * 60)) / (1000 * 60));
    const formattedMinutes = String(Math.max(0, minutes)).padStart(2, '0');
    parts.push(formattedMinutes);
  }
  if (props.seconds) {
    const seconds = Math.floor((difference.value % (1000 * 60)) / 1000);
    const formattedSeconds = String(Math.max(0, seconds)).padStart(2, '0');
    parts.push(formattedSeconds);
  }

  return parts.join(':');
});

const updateTime = () => {
  now.value = now.value.add(1, 'second');
  const elapsedDuration = now.value.diff(startDate);
  difference.value = targetDate.diff(now.value);
  progress.value = Number(Math.min(100, Math.max(0, (elapsedDuration / totalDuration) * 100)).toFixed(2));
  if (progress.value === 100) removeExpiredPromocode();
};

const timeSpanWidth = computed(() => {
  let format = 0;
  if (props.days) format += 3;
  if (props.hours) format += 3;
  if (props.minutes) format += 3;
  if (props.seconds) format += 2;
  return format + 0.5;
});

updateTime();

const removeExpiredPromocode = async () => {
  if (props.project.action_block.promocode_timer?.promocode.code === props.project.action_block.promocode?.code) {
    emit(
      'update-action-block',
      await getActionBlock({
        projectId: props.project.id,
        services: props.cart.map((item) => item.service),
      })
    );
  }
};

const handleWindowFocus = async () => {
  emit('update-action-block');
};

watch(
  () => props.now,
  (newValue) => {
    now.value = dayjs(newValue);
  }
);

onMounted(() => {
  setInterval(updateTime, 1000);
  window.addEventListener('focus', handleWindowFocus);
});

onBeforeUnmount(() => {
  window.removeEventListener('focus', handleWindowFocus);
});
</script>

<template>
  <div
    v-if="!isExpired"
    class="black-friday-timer"
  >
    <div
      :style="{ width: `${100 - progress}%` }"
      class="progress"
    />
    <span> {{ label }} </span>
    <span
      class="time"
      :style="{ width: `${timeSpanWidth}ch` }"
    >
      <span>
        {{ stringDifference }}
      </span>
    </span>
  </div>
</template>

<style scoped lang="scss">
.black-friday-timer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px 12px 12px;
  gap: 16px;
  align-self: stretch;
  border-radius: 20px;
  background: var(--accent-accent-dark, #2b2433);
  color: white;

  @include media-breakpoint-down(md) {
    margin: 0 16px;
  }

  .progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    background: white;
    opacity: 0.2;
    transition: width 1s ease;
  }

  .time {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 12px;
    background: white;
    color: var(--accent-accent-dark, #2b2433);
  }
}
</style>
