<script setup lang="ts">
import type { PaymentType } from '~/api/projects';
import { getPaymentLink } from '~/api/projects';
import type { PayItem, Project } from '~/api/types';
import { sendEvents } from '~/api/analytics';
import * as Sentry from '@sentry/vue';
import VButton from '~/components/VButton.vue';
import PaymentErrorModal from '~/components/pages/projects/payment/PaymentErrorModal.vue';

const loading = ref(false);
const stripeLoading = ref(false);
const sbpLoading = ref(false);

type Props = {
  project: Project;
  cart: PayItem[];
  type: PaymentType;
};

const props = defineProps<Props>();
const isError = ref(false);

const defaultPrice = computed(() => props.cart.reduce((sum: number, item: PayItem) => sum + item.price, 0));
const discountPrice = computed(() => props.cart.reduce((sum: number, item: PayItem) => sum + (item.discount_price ?? item.price), 0));

const getPaymentLinkFromCart = async (paymentType: PaymentType) => {
  const services = props.cart.map((i) => i.service);
  const projectId = props.project.id.toString();
  const promocode = props.project.action_block.promocode?.code || undefined;

  return await getPaymentLink(projectId, services, paymentType, promocode);
};

const getStripeLink = async () => {
  stripeLoading.value = true;
  const { payment_link } = await getPaymentLinkFromCart('en');
  stripeLoading.value = false;
  return payment_link;
};

const getTinkoffLink = async () => {
  loading.value = true;
  const { payment_link } = await getPaymentLinkFromCart('ru');
  loading.value = false;
  return payment_link;
};

const getSbpLink = async () => {
  sbpLoading.value = true;
  const { payment_link } = await getPaymentLinkFromCart('sbp');
  sbpLoading.value = false;
  return payment_link;
};

const navigateToPayment = async (linkType: PaymentType) => {
  if (linkType === 'en') {
    const stripeLink = await getStripeLink();
    if (!stripeLink) throw new Error('Null Stripe link in response');
    return navigateTo(stripeLink, { external: true });
  } else if (linkType === 'ru') {
    const tinkoffLink = await getTinkoffLink();
    if (!tinkoffLink) throw new Error('Null Tinkoff link in response');
    return navigateTo(tinkoffLink, { external: true });
  } else if (linkType === 'sbp') {
    const sbpLink = await getSbpLink();
    if (!sbpLink) throw new Error('Null SBP link in response');
    return navigateTo(sbpLink, { external: true });
  }
};

const onPaymentSubmit = async (linkType: PaymentType) => {
  await sendEvents([
    {
      event_name: 'submitPayForm',
      project_id: props.project.id,
      amount: discountPrice.value,
      services: props.cart.map((i) => i.service),
      ts: new Date().getTime(),
    },
  ]);

  await navigateToPayment(linkType).catch((err) => {
    Sentry.captureException(err);
    refreshNuxtData('project');
    isError.value = true;
  });
};
</script>

<template>
  <div>
    <v-button
      v-if="props.type === 'ru'"
      schema="primary"
      class="new-button new-button_md new-button_primary animation-scale w-100"
      :class="{ 'new-button_disabled': loading }"
      :loading="loading"
      @click="onPaymentSubmit('ru')"
    >
      {{ props.project.action_block.buttonText || 'Оплатить' }}
      <span
        :style="{
          opacity: discountPrice < defaultPrice ? 0.3 : 1,
          'text-decoration': discountPrice < defaultPrice ? 'line-through' : 'none',
        }"
      >
        {{ defaultPrice }}&nbsp;₽
      </span>
      <span v-if="discountPrice < defaultPrice"> {{ discountPrice }}&nbsp;₽ </span>
    </v-button>
    <v-button
      v-else-if="props.type === 'en'"
      schema="secondary"
      class="new-button new-button_secondary animation-scale foreign-button w-100 mt-2"
      :class="{ 'new-button_disabled': stripeLoading }"
      :loading="stripeLoading"
      @click="onPaymentSubmit('en')"
    >
      Оплата зарубежной картой
    </v-button>
    <v-button
      v-else-if="props.type === 'sbp'"
      class="new-button new-button_md new-button_primary animation-scale w-100 sbp-button mt-2"
      :disabled="sbpLoading"
      @click="onPaymentSubmit('sbp')"
    >
      <nuxt-img
        src="/images/sbp.svg"
        alt="СБП"
        title="СБП"
        height="30"
      />
    </v-button>
  </div>
  <payment-error-modal
    :is-open="isError"
    @close="isError = false"
  />
</template>

<style scoped lang="scss">
.action-block-wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  box-sizing: content-box;
}

.action-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 32px;
  background: white;

  .foreign-button {
    &:not(:hover) {
      background: white !important;
    }
  }

  .new-button {
    font-weight: 500;
  }

  .sbp-button {
    &:not(:hover) {
      background: #1d1346 !important;
    }

    &:hover {
      background: #1d1346 !important;
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__type {
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
      width: 70%;
    }

    &__price-and-icon {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &__base-price {
        font-size: 36px;
        font-weight: 500;
        line-height: 130%;
        color: $foreground-theme;
      }
    }
  }

  &__header {
    h5 {
      margin-bottom: 4px;
    }

    p {
      margin-bottom: 0;
      color: #5047e6a3;
    }
  }

  &__select-group {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__collapse {
      background: $background-theme-fade;
      border-radius: 12px;
      padding: 12px;

      &__title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        font-size: 14px;
      }

      &__content {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $foreground-gray;

        &:first-letter {
          text-transform: capitalize;
        }
      }
    }
  }

  &__select-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 10px 12px;
    border-radius: 12px;
    background: white;
  }

  &__select-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    gap: 8px;

    p {
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      align-items: center;
    }
  }

  &__select-item-price {
    color: $foreground-theme;
    font-weight: 600;
  }

  &__select-item-body {
    color: $foreground-gray;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      color: $foreground-contrast;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      display: flex;
      gap: 8px;
      margin: 0;
      padding: 0;

      svg {
        opacity: 0.7;
      }
    }
  }

  &_fixed {
    position: fixed;
    z-index: 5;
    bottom: 0;
    left: 50%;
    padding: 16px 20px;
    transform: translateX(-50%);
    width: 100%;
    display: none;
    font-size: 14px;
    font-weight: 500;

    background: white;
    box-shadow: $new-shadow;
    @include media-breakpoint-down(lg) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      box-shadow: 0 -4px 24px rgba(#00000017, 0.08);
    }

    border-radius: 32px 32px 0 0;

    .action-block_fixed__text {
      display: flex;

      flex-direction: column;
      gap: 4px;

      &__heading {
        color: $foreground-contrast;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
      }

      &__description {
        color: $foreground-theme;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;

        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
</style>
