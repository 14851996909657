<script setup lang="ts">
import { DrawerContent, DrawerOverlay, DrawerPortal, DrawerRoot } from 'vaul-vue';

type Props = {
  open: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits(['close']);

const onUpdate = (isOpen: boolean) => {
  if (!isOpen) emit('close');
};
</script>

<template>
  <client-only>
    <DrawerRoot
      :open="props.open"
      :prevent-scroll-restoration="false"
      @update:open="onUpdate"
    >
      <DrawerPortal to="body">
        <div
          v-bind="$attrs"
          style="width: 100%"
        >
          <DrawerOverlay class="drawer-overlay" />
          <DrawerContent class="drawer-content">
            <div class="drawer">
              <div class="drawer-handle" />
              <slot />
            </div>
          </DrawerContent>
        </div>
      </DrawerPortal>
    </DrawerRoot>
  </client-only>
</template>

<style scoped lang="scss">
.drawer-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.drawer-content {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-radius: 32px 32px 0 0;
  z-index: 1001;

  .drawer-handle {
    background: $background-button-secondary-hover;
    border-radius: 16px;
    width: 64px;
    height: 8px;
    margin: 0 auto;
  }

  .drawer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    padding: 16px 24px 64px 24px;
  }
}
</style>
