<script setup lang="ts">
import { getProject, logView } from '~/api/projects';
import type { Project } from '~/api/types';
import { useUserStore } from '~/stores/UserStore';
import NewProject from '~/components/pages/projects/Project.vue';
import OldProject from '~/components/deprecated/Project.vue';
import { load } from '@fingerprintjs/fingerprintjs';

const userStore = useUserStore();
const analyticsStore = useAnalyticsStore();

const route = useRoute();
const { data: project, refresh } = await useAsyncData<Project>('project', () => getProject(route.params.id!.toString()), {
  watch: [() => userStore.user],
});

const timerInterval = ref();

onMounted(async () => {
  if (project.value?.long_poll) {
    timerInterval.value = setInterval(async () => {
      if (project.value?.long_poll) {
        await refresh();
      } else {
        clearInterval(timerInterval.value);
      }
    }, 5000);
  }
  if (window && 'yaCounter95948387' in window) {
    const setFp = async () => {
      const fp = await load();
      const { visitorId } = await fp.get();
      await logView('view', project.value?.id?.toString() || null, window?.yaCounter95948387?.getClientID() || null, visitorId);
    };

    await setFp();
  }
  analyticsStore.openProjectPage(project.value?.id || 0);
});
onBeforeUnmount(() => {
  clearInterval(timerInterval.value);
});

definePageMeta({
  middleware: ['slug'],
});

if (project.value?.status) {
  const title = ref(project.value.title + ' | ' + (project.value.idea || 'Нейросеть Бегемот'));
  const description = ref(project.value.description);
  const meta = [
    {
      name: 'title',
      content: title.value,
    },
    {
      hid: 'description',
      name: 'description',
      content: description.value,
    },

    {
      property: 'og:title',
      content: title.value,
    },
    {
      property: 'og:description',
      content: description.value,
    },
    {
      property: 'og:type',
      content: 'article',
    },
    {
      property: 'og:url',
      content: project.value.url,
    },
    {
      itemprop: 'learningResourceType',
      content: 'article',
    },
  ];

  if (project.value?.picture_url) {
    meta.push({
      property: 'og:image',
      content: project.value.picture_url,
    });
  }
  if (project.value?.no_index) {
    meta.push({
      name: 'robots',
      content: 'noindex',
    });
  }
  useHead({
    meta,
    title: title.value,
  });
} else {
  useHead({
    title: project.value?.prompt,
    meta: [
      {
        name: 'description',
        content: project.value?.prompt,
      },
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
  });
}

watch(
  project,
  (value) => {
    if (value?.is_owner && !value.payed_status) useTelegramPopupStore().isHidden = true;
    else useTelegramPopupStore().isHidden = false;
  },
  { immediate: true }
);

watch(
  () => project.value?.status,
  (newStatus, oldStatus) => {
    if (!project.value) return;
    if (newStatus === 0 && oldStatus === undefined) analyticsStore.generationStart(project.value.id);
    if (oldStatus === 0 && newStatus === 1) analyticsStore.generationEnd(project.value.id);
  },
  { immediate: true }
);

if (!project.value) throw createError({ statusCode: 404 });
</script>

<template>
  <template v-if="project && project.status == 1">
    <div>
      <NewProject
        v-if="project.new_project_view"
        :project="project"
      />
      <OldProject
        v-else
        :project="project"
      />
    </div>
  </template>
  <v-loader
    v-else
    :data="project?.display_status"
  />
</template>
