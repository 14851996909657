<script setup lang="ts">
import VButton from '~/components/VButton.vue';
import { copyProjectById } from '~/api/projects';
import type { Project } from '~/api/types';

type Props = {
  project: Project;
};

const props = defineProps<Props>();

const userStore = useUserStore();
const projectStore = useProjectStore();
const authStore = useAuthStore();

const loading = ref(false);
const willCopyAfterLogin = useCookie<boolean>('willCopyAfterLogin', { maxAge: 60, default: () => false });

const copyProject = async () => {
  if (userStore.user) {
    loading.value = true;
    await copyProjectById(props.project.id.toString()).then(function (res: Project) {
      projectStore.isCopied = true;
      navigateTo(`/projects/${res.id}`);
    });
    loading.value = false;
  } else {
    willCopyAfterLogin.value = true;
    authStore.showAuth = true;
  }
};

watch(
  () => userStore.user,
  (userValue) => {
    if (userValue && willCopyAfterLogin.value && props.project?.id) {
      willCopyAfterLogin.value = false;
      copyProject();
    }
  },
  { immediate: true }
);
</script>

<template>
  <v-button
    class="animation-scale"
    schema="primary"
    :disabled="loading"
    style="width: 100%"
    @click="copyProject()"
  >
    {{ props.project.action_block.buttonText || 'Оплатить' }}
  </v-button>
</template>

<style scoped lang="scss"></style>
