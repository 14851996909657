<script setup lang="ts">
import type { Project, StructuredChapter } from '~/api/types';

type Props = {
  project: Project;
  chapters: StructuredChapter[];
};

const props = defineProps<Props>();
</script>

<template>
  <div
    v-if="Object.keys(props.chapters).length > 1"
    class="project-page__content-list"
  >
    <div class="project-page__content-list__header">
      <h2>Содержание</h2>
    </div>
    <template
      v-for="(chapter, idx) in props.chapters"
      :key="idx"
    >
      <a
        :href="`#${chapter.id}`"
        target="_self"
        class="project-page__content-list-item chapter"
      >
        {{ chapter.title }}
      </a>
      <template v-if="!chapter.is_paragraph">
        <template
          v-for="(paragraph, idx) in chapter.paragraphs"
          :key="idx"
        >
          <a
            :href="`#${paragraph.id}`"
            target="_self"
            class="project-page__content-list-item"
          >
            {{ paragraph.title }}
          </a>
        </template>
      </template>
    </template>
  </div>
</template>

<style scoped lang="scss">
.project-page__content-list {
  display: flex;
  flex-direction: column;
  padding: 64px;

  @include media-breakpoint-down(md) {
    padding: 48px;
  }

  .project-page__content-list__header {
    h2 {
      font-size: 18px;
      font-weight: 600;
      font-family: 'Times New Roman', serif;
    }
  }

  .project-page__content-list-item {
    color: $foreground-contrast;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    transition: 0.2s;
    text-decoration: underline solid transparent;
    text-underline-offset: 5px;
    padding: 2px 0 2px 20px;

    &:not(.chapter) {
      border-left: 1px solid rgba($foreground-contrast, 0.1);
    }

    &.chapter {
      font-weight: 600;
      padding: 8px 0;
      margin-left: 0;
    }

    &:hover {
      text-decoration: underline solid $foreground-contrast;

      &:after {
        left: 4px;
      }
    }
  }
}
</style>
