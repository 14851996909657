<script setup lang="ts">
import { VueMermaidRender } from 'vue-mermaid-render';

type ImageLayoutType = {
  type: 'mermaid' | 'image';
};

type ImageProps = {
  src?: string;
  alt?: string;
  text?: string;
};

type MermaidProps = {
  code?: string;
  text?: string;
};

type ImageLayoutProps = ImageLayoutType & ImageProps & MermaidProps;

const props = defineProps<ImageLayoutProps>();

function wrapSquareBracketContents(input) {
  return input.replace(/\[(.*?)]/g, '["$1"]');
}
</script>

<template>
  <div class="image-layout">
    <template v-if="props.type === 'image' && props.src && props.alt">
      <NuxtImg
        itemprop="image"
        :src="props.src"
        :alt="props.alt"
      />
      <span
        v-if="props.text"
        class="image-layout__text"
      >
        {{ props.text }}
      </span>
    </template>
    <template v-else-if="type === 'mermaid'">
      <VueMermaidRender
        :config="{ theme: 'neutral', look: 'handDrawn', fontSize: 100, fontFamily: 'Manrope' }"
        :content="wrapSquareBracketContents(props.code)"
      />
      <span
        v-if="props.text"
        class="image-layout__text"
      >
        {{ props.text }}
      </span>
    </template>
  </div>
</template>

<style scoped lang="scss">
.image-layout {
  :first-child {
    border-radius: 12px;
    //border: 1px solid $background-button-secondary-hover;
    width: 100%;
    margin-bottom: 12px;
  }

  .image-layout__text {
    color: $foreground-gray;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
}
</style>
