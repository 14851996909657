import { getApiEndpoint } from '~/api/auth';
import type { Project, Promocode, User } from '~/api/types.ts';
import { authFetch } from '~/api/utils';

export const getUser = async (): Promise<User> => {
  return await authFetch(getApiEndpoint() + 'user');
};

export const getUserProjects = async (): Promise<Project[]> => {
  return await authFetch(getApiEndpoint() + 'user/projects/', {
    method: 'GET',
  });
};

export const changeEmail = async ({ email }: { email: string }) => {
  return await authFetch(getApiEndpoint() + 'user/email/', {
    method: 'POST',
    body: {
      email,
    },
  });
};

interface CreateDeleteRequestProps {
  reason: string;
}
export const createDeleteRequest = async ({ reason }: CreateDeleteRequestProps) => {
  return await authFetch<{ promocode: Promocode }>(getApiEndpoint() + 'user/deleteRequest/create', {
    method: 'POST',
    body: { reason },
  });
};

export const revertDeleteRequest = async () => {
  return await authFetch(getApiEndpoint() + `user/deleteRequest/delete`, {
    method: 'POST',
  });
};

export const approveDeleteRequest = async () => {
  return await authFetch(getApiEndpoint() + `user/deleteRequest/approve`, {
    method: 'POST',
  });
};
